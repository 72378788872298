@import "element-content";
@import "row-block";
@import "grid-block";
@import "single-image-block";
@import "carousel-block";
@import "fullscreen-carousel-block";
@import "video-block";
@import "cta-block";

@import "components/image-text-component";
@import "components/video-text-component";

@import "block-background";
@import "block-headline-color";

// Generic wrapper class for all blocks
.element {
  padding: @space-4 0;

  &.element--noPaddingTop {
    padding-top: 0;
  }

  &.element--noPaddingBottom {
    padding-bottom: 0;
  }

  .ce-blockTitle {
    .ms(3);
    margin-top: 0;
    font-family: @font-helvetica-bold;

    @media screen and (min-width: @breakpoint-sm) {
      .ms(4);
    }

    @media screen and (min-width: @breakpoint-lg) {
      .ms(7);
    }
  }

  .ce-blockContent {
    .ms(1);
    line-height: 1.5;
  }

  iframe {
    max-width: 100%;
  }

  .ss-htmleditorfield-file {
    &.embed {
      max-width: 100%;
    }
  }
}
