@import (reference) "../base";

.ce__serviceplatform__fullscreencarouselblock {

  main.ce-blockPage &:first-child {
    padding-top: 0;

    .ce-blockTitle {
      padding-top: @space-4;
    }
  }

  .ce-fullscreenCarousel-slide {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 75%; // 16:12
    z-index: 5;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (min-width: @breakpoint-xs) {
      padding-bottom: 50%; // 16:8
    }

    @media screen and (min-width: @breakpoint-sm) {
      padding-bottom: 40%; // 16:6.5
    }

    @media screen and (min-width: @breakpoint-lg) {
      padding-bottom: 25%; // 16:4
    }

    @media screen and (min-width: @breakpoint-xl) {
      padding-bottom: 18.75%;
    }

    @media screen and (min-width: @breakpoint-xxl) {
      padding-bottom: 12.5%;
    }

    .ce-fullscreenCarousel-slideLink {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    .ce-fullscreenCarousel-slide-overlay {
      width: calc(100% ~'-' 120px);
      max-width: @breakpoint-xl;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      > div {
        background: fade(@color-mono-0, 50);
        color: @color-mono-100;
        padding: @space-4;
        position: relative;
        z-index: 50;

        @media screen and (min-width: @breakpoint-xl) {
          max-width: 50%;
        }

        .ce-fullscreenCarousel-slide-title {
          font-weight: bold;
          margin: 0 0 @space-3 0;
          .ms(3);

          @media screen and (min-width: @breakpoint-xl) {
            .ms(7);
          }
        }
      }

      &.ce-fullscreenCarousel-slide-overlay--noBackground {
        > div {
          background: none;

          .ce-fullscreenCarousel-slide-title {
            // same as ".intro .intro__title" on the sunchemical site
            text-shadow: 0 0 20px #2b6a80;
          }
        }
      }
    }
  }

  .VueCarousel {
    .VueCarousel-pagination {
      .VueCarousel-dot-container {
        margin-top: @space-2 !important;
        display: flex;
        justify-content: center;

        .VueCarousel-dot {
          outline: 0 !important;
          background-color: transparent !important;
          border: 2px solid @color-mono-80;
          padding: 0 !important;
          margin: @space-1 !important;

          &.VueCarousel-dot--active {
            background-color: @color-text-base !important;
            border: 0;
            width: 14px !important;
            height: 14px !important;
          }
        }
      }
    }

    .VueCarousel-navigation {
      opacity: 0;
      transition: opacity 250ms ease-in-out;

      .VueCarousel-navigation-button {
        margin: 0 !important;
        outline: 0 !important;
        transition: color 250ms ease-in-out;

        &.VueCarousel-navigation-prev {
          transform: translate(0, -50%) scale(0.6) !important;
        }
        &.VueCarousel-navigation-next {
          transform: translate(0, -50%) scale(0.6) !important;
        }

        &:hover {
          color: @color-light;
        }
      }
    }

    &:hover {
      .VueCarousel-navigation {
        opacity: 1;
      }
    }
  }
}
