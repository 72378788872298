@import (reference) "../base";

.ce__serviceplatform__calltoactionblock {
  .ce-ctaBlock-content {
    .ce-ctaBlock-text {
      p, ul {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .ce-ctaBlock-cta {
      min-width: 200px;
      padding: @space-3;
      background: @color-mono-90;
      border-radius: @border-radius;

      .ce-ctaBlock-ctaLink {
        margin-top: @space-2;
      }
    }
  }

  .ce-ctaBlock-layout {
    &.content_left, &.content_right {
      .ce-ctaBlock-content {
        display: flex;
        align-items: flex-start;

        .ce-ctaBlock-text {
          .flex-auto;
        }
      }
    }

    &.content_left {
      .ce-ctaBlock-content {
        .ce-ctaBlock-text {
          padding-right: @space-5;
        }
      }
    }

    &.content_right {
      .ce-ctaBlock-content {
        flex-direction: row-reverse;

        .ce-ctaBlock-text {
          padding-left: @space-5;
        }
      }
    }

    &.box_full_width {
      .ce-ctaBlock-content {
        .ce-ctaBlock-cta, .ce-ctaBlock-buttonCta {
          margin-top: @space-4;
        }
        .ce-ctaBlock-buttonCta a.ce-btn {
          width: 100%;
        }
      }
    }

    &.centered {
      text-align: center;

      .ce-ctaBlock-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .ce-ctaBlock-cta, .ce-ctaBlock-buttonCta {
          margin-top: @space-4;
        }
      }
    }
  }

  &.ce-blockBackground-light_grey {
    .ce-ctaBlock-content .ce-ctaBlock-cta {
      background: @color-mono-100;
    }
  }

  &.ce-blockBackground-dark_grey {
    .ce-ctaBlock-content .ce-ctaBlock-cta {
      background: @color-mono-100;
      color: @color-text-base;

      a {
        color: @color-light;

        &:hover {
          color: @color-main;
        }
      }
    }
  }

  &.ce-blockBackground-primary_light {
    .ce-ctaBlock-content .ce-ctaBlock-cta {
      background: @color-mono-100;
      color: @color-text-base;

      a {
        color: @color-light;

        &:hover {
          color: @color-main;
        }
      }
    }
  }
}
