@import "../includes/base";
@import "../includes/blocks/index";

h1 {
  margin: 0 0 @space-3;
  padding: 0;
  font-weight: 700;
}

main.ce-blockPage {
  padding-bottom: @space-4;
}
