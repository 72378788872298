@import (reference) "../base";

.ce__serviceplatform__singleimageblock {
  main.ce-blockPage &:first-child {
    padding-top: 0;
  }

  .ce-imageBlockLayout--full_width {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .ce-imageBlock-imageWrapper {
    img {
      max-width: 100%;
    }
  }
}
