@import (reference) "../base";

.ce__serviceplatform__gridblock {
  .ce-gridBlock_header {
    margin-bottom: @space-4;
  }

  .ce-gridBlock_components {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -@space-3;
  }

  .ce-blockComponent {
    padding: @space-3;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;

    .ce-blockComponent_content {
      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .ce-imageTextComponent_image {
      .flex-none;
      margin-bottom: @space-3;
    }

    .ce-videoTextComponent_video {
      .flex-none;
      margin-bottom: @space-3;
    }

    .ce-blockComponent_title {
      .ms(3);
      font-family: @font-helvetica-bold;
      margin: 0 0 @space-3 0;

      a {
        color: @color-sc-blue;

        &:hover {
          color: @color-sc-red;
        }
      }
    }

    .ce-blockComponent_content {
      .ms(1);
      line-height: 1.5;
    }

    img {
      max-width: 100%;
    }
  }

  .ce-gridBlockLayout {
    .ce-blockComponent {
      width: 100%;
    }

    &.ce-gridBlockLayout-2-columns {
      .ce-blockComponent {
        @media screen and (min-width: @breakpoint-xs) {
          width: 50%;
        }
      }
    }
    &.ce-gridBlockLayout-3-columns {
      .ce-blockComponent {
        @media screen and (min-width: @breakpoint-xs) {
          width: 50%;
        }
        @media screen and (min-width: @breakpoint-lg) {
          width: 33.33%;
        }
      }
    }
    &.ce-gridBlockLayout-4-columns {
      .ce-blockComponent {
        @media screen and (min-width: @breakpoint-xs) {
          width: 50%;
        }
        @media screen and (min-width: @breakpoint-sm) {
          width: 33.33%;
        }
        @media screen and (min-width: @breakpoint-lg) {
          width: 25%;
        }
      }
    }
  }
}
