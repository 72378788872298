
@import (reference) '~styles/includes/base';
@import '~styles/includes/search-field.less';

.ce-searchField {
  margin-bottom: @space-3; // TODO: @space-5 as soon as type switcher is back

  .vue-simple-suggest {
    position: relative;

    > ul {
      .list-reset;
      margin: 0;
    }

    .suggestions {
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      background: fade(@color-mono-100, 93);
      z-index: 1000;
      box-shadow: 0 3px 5px rgba(0,0,0,.16);

      .suggest-item {
        cursor: pointer;
        .noselect();
        .ms(1);

        &.hover, &.selected {
          background-color: @color-light;
          color: @color-mono-100;
        }
      }

      .suggest-item, .misc-item {
        padding: @space-2;
      }
    }
  }
}
