@import (reference) "../base";

.ce__serviceplatform__carouselblock {
  .ce-imageTextComponent, .ce-videoTextComponent {
    padding: @space-3;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;

    .ce-blockComponent_title {
      .ms(3);
      font-family: @font-helvetica-bold;
      margin: @space-3 0;

      a {
        color: @color-sc-blue;

        &:hover {
          color: @color-sc-red;
        }
      }
    }

    .ce-imageTextComponent_image, .ce-videoTextComponent_video {
      .flex-none;
    }

    .ce-blockComponent_content {
      .ms(1);
      line-height: 1.5;
    }

    img {
      max-width: 100%;
    }
  }

  .VueCarousel {
    margin: 0 -@space-3;

    @media screen and (min-width: @breakpoint-lg) and (max-width: (@breakpoint-xxl - 1)) {
      margin: 0 @space-4;
    }

    .VueCarousel-pagination {
      .VueCarousel-dot-container {
        margin-top: 0 !important;

        .VueCarousel-dot {
          margin-top: 0 !important;
          outline: 0 !important;

          &.VueCarousel-dot--active {
            background-color: @color-light !important;
          }
        }
      }
    }

    .VueCarousel-navigation-button {
      color: @color-light;
      outline: 0 !important;
    }

    @media screen and (max-width: (@breakpoint-lg - 1)) {
      .VueCarousel-navigation {
        display: none;
      }
    }
  }
}
