@import (reference) "base";

.ce-searchField {
  margin-bottom: @space-5;
  position: relative;

  button[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    padding: 11px @space-3;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    outline: none;
    display: inline-block;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    .noselect();
    color: @color-text-base;
  }

  input[type=text] {
    display: block;
    width: 100%;
    height: 40px;
    padding: @space-2;
    padding-right: (@space-2 + 48px);
    border: 1px solid @color-mono-70;
    background: none;
    transition: all 250ms ease-in-out;
    outline:none;
    box-shadow: none;
    -webkit-appearance: none;
    border-radius: @border-radius;

    &:focus {
      border-color: @color-light;
    }
  }
}
