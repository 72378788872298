.ce-searchField {
  margin-bottom: 48px;
  position: relative;
}
.ce-searchField button[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 11px 16px;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  outline: none;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  background: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #464144;
}
.ce-searchField input[type=text] {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px;
  padding-right: 56px;
  border: 1px solid #b3b3b3;
  background: none;
  transition: all 250ms ease-in-out;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 4px;
}
.ce-searchField input[type=text]:focus {
  border-color: #ed3024;
}
.ce-searchField {
  margin-bottom: 16px;
}
.ce-searchField .vue-simple-suggest {
  position: relative;
}
.ce-searchField .vue-simple-suggest > ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.ce-searchField .vue-simple-suggest .suggestions {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background: rgba(255, 255, 255, 0.93);
  z-index: 1000;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
}
.ce-searchField .vue-simple-suggest .suggestions .suggest-item {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 18px;
}
.ce-searchField .vue-simple-suggest .suggestions .suggest-item.hover,
.ce-searchField .vue-simple-suggest .suggestions .suggest-item.selected {
  background-color: #ed3024;
  color: #fff;
}
.ce-searchField .vue-simple-suggest .suggestions .suggest-item,
.ce-searchField .vue-simple-suggest .suggestions .misc-item {
  padding: 8px;
}
