@import (reference) "../base";

.ce__serviceplatform__rowblock {
  .ce-rowBlock_header {
    margin-bottom: @space-5;
  }

  .ce-blockComponent {
    display: flex;
    flex-direction: column-reverse;

    .ce-blockComponent_content {
      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: @space-5;
    }

    .ce-blockComponent_title {
      .ms(3);
      font-family: @font-helvetica-bold;
      margin: 0 0 @space-3 0;

      a {
        color: @color-sc-blue;

        &:hover {
          color: @color-sc-red;
        }
      }
    }

    .ce-blockComponent_content {
      .ms(1);
      line-height: 1.5;
    }

    .ce-imageTextComponent_image {
      margin-bottom: @space-3;
      .flex-none;

      img {
        max-width: 100%;
        display: block;
      }
    }

    .ce-videoTextComponent_video {
      margin-bottom: @space-3;
      .flex-none;
    }

    @media screen and (min-width: @breakpoint-sm) {
      display: flex;
      margin: 0 -@space-3;

      > div {
        padding: 0 @space-3;
      }

      .ce-blockComponent_contentWrapper {
        width: 50%;
      }

      .ce-imageTextComponent_image, .ce-videoTextComponent_video {
        margin-bottom: 0;
        width: 50%;
      }
    }
  }

  @media screen and (min-width: @breakpoint-sm) {
    .ce-rowBlockLayout {
      &.content_left {
        .ce-blockComponent {
          flex-direction: row;
        }
      }

      &.content_right {
        .ce-blockComponent {
          flex-direction: row-reverse;
        }
      }

      &.alternating {
        .ce-blockComponent {
          flex-direction: row;
        }
        .ce-blockComponent:nth-child(2n) {
          flex-direction: row-reverse;
        }
      }
    }
  }
}
