@import "./block-page";

main.ce-productsPage {
  padding-bottom: 0;

  .ce__serviceplatform__industryboxesblock {
    background: @color-mono-95;

    h1 {
      margin: @space-3 0 @space-2;
    }
  }

  .ce-blockPage-title {
    text-align: center;

    h1 {
      margin-bottom: @space-2;
    }

    + .ce__serviceplatform__industryboxesblock {
      padding-top: 0;
    }
  }

  .ce-contentPromotionSnippet {
    margin: 0;
    text-align: left;
    padding: @space-2;
    display: flex;
    .flex-auto;
    align-items: center;
    background: @color-mono-100;
    border-radius: @border-radius;

    .ce-contentPromotionSnippet-imageContainer {
      height: 100%;

      a {
        display: block;
        width: 200px;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    > div {
      margin: 0;
    }

    img {
      height: 125px;
      width: auto;
    }

    > p {
      .ms3;
      padding: 0 @space-3 @space-1;
    }

    @media only screen and (max-width: (@breakpoint-xs - 1)) {
      flex-direction: column;
      margin-left: -@space-4;
      margin-right: -@space-4;

      > div, img {
        width: 100%;
        height: auto;
      }
    }
  }

  .ce-industryCard {
    display: flex;
    .flex-auto;
    min-height: 140px;
    border-radius: @border-radius;
    padding: @space-2;
    background: @color-mono-100;

    .ce-industryCard-image {
      width: 200px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .ce-industryCard-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 @space-3 @space-1;
      text-align: left;

      h3, p {
        margin: @space-1 0;
      }

      h3 {
        a {
          color: @color-sc-blue;

          &:hover {
            color: @color-sc-red;
          }
        }
      }

      .ce-industryCard-footer {
        .ms(-1);
        font-weight: 700;
        align-self: flex-end;
      }
    }

    @media only screen and (max-width: (@breakpoint-xs - 1)) {
      flex-direction: column;
      height: auto !important;
      margin-left: -@space-4;
      margin-right: -@space-4;

      .ce-industryCard-image {
        width: 100%;
        height: 250px;
      }

      .ce-industryCard-content {
        padding: @space-3 0 @space-1;
      }
    }
  }
}
