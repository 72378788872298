
@import (reference) '~styles/includes/base';

.ce-fieldWrapper--label {
  &:not(:first-child) {
    margin-top: @space-5;
  }
}

.ce-fieldWrapper--section_header {
  .ms(3);
  font-weight: 700;
  &:not(:first-child) {
    margin-top: @space-5;
  }

  + .ce-fieldWrapper--label {
    margin-top: 0;
  }
}

.ce-fieldWrapper--spacer {
  margin-top: @space-5;
}
