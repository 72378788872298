@import (reference) "../base";

.element {
  &.ce-blockBackground-light_grey {
    background: @color-mono-92;
  }
  &.ce-blockBackground-ultralight_grey {
    background: @color-mono-95;
  }

  &.ce-blockBackground-light_grey, &.ce-blockBackground-ultralight_grey {
    .ce-field {
      input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="search"], select, textarea {
        border: 1px solid @color-mono-100;
        background: @color-mono-100;

        &:focus {
          border-color: @color-light;
        }
      }

      .multiselect {
        .multiselect__tags, .multiselect__content-wrapper {
          border: 1px solid @color-mono-100;
        }
      }
    }

    .VueCarousel {
      .VueCarousel-pagination {
        .VueCarousel-dot-container {
          .VueCarousel-dot {
            background-color: @color-mono-70 !important;
            &.VueCarousel-dot--active {
              background-color: @color-light !important;
            }
          }
        }
      }
    }
  }

  &.ce-blockBackground-dark_grey {
    background: @color-mono-50;
    color: @color-mono-100;

    a:not(.ce-btn) {
      color: @color-lighter;

      &:hover {
        color: @color-light;
      }
    }

    .VueCarousel {
      .VueCarousel-pagination {
        .VueCarousel-dot-container {
          .VueCarousel-dot {
            background-color: @color-mono-70 !important;
            &.VueCarousel-dot--active {
              background-color: @color-mono-100 !important;
            }
          }
        }
      }

      .VueCarousel-navigation-button {
        color: @color-mono-100;
      }
    }
  }

  &.ce-blockBackground-primary_light, &.ce-blockBackground-sun_chemical_red {
    background: @color-light;
    color: @color-mono-100;

    a {
      color: #b3ebff;

      &:hover {
        color: @color-mono-100;
      }
    }

    .ce-btn-light {
      background: @color-mono-100;
      color: @color-light;

      &:hover {
        background: @color-mono-90;
      }
    }

    input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="search"], select, textarea {
      &:focus {
        border-color: @color-mono-20;
      }
    }

    .VueCarousel {
      .VueCarousel-pagination {
        .VueCarousel-dot-container {
          .VueCarousel-dot {
            background-color: @color-mono-70 !important;
            &.VueCarousel-dot--active {
              background-color: @color-mono-100 !important;
            }
          }
        }
      }

      .VueCarousel-navigation-button {
        color: @color-mono-100;
      }
    }
  }

  &.ce-blockBackground-sun_chemical_light_green {
    background: @color-sc-light-green;
    color: @color-mono-100;
  }

  &.ce-blockBackground-sun_chemical_dark_green {
    background: @color-sc-dark-green;
    color: @color-mono-100;
  }

  &.ce-blockBackground-sun_chemical_blue {
    background: @color-sc-blue;
    color: @color-mono-100;
  }

  &.ce-blockBackground-sun_chemical_yellow {
    background: @color-sc-yellow;
  }
}
